import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import CarouselSection from './CarouselSection'
import ContentImageSection from './ContentImageSection'
import HeroFooterSection from './HeroFooterSection'
import HeroSection from './HeroSection'
import ListImageSection from './ListImageSection'
import TitleSection from './TitleSection'

const SECTION_TYPES = {
  CAROUSEL: 'carousel',
  CONTENT_IMAGE: 'content_image',
  HERO: 'hero',
  HERO_FOOTER: 'hero_footer',
  LIST_IMAGE: 'list_image',
  TITLE: 'title',
}

// LandingSectionsRenderer: A component that renders different sections on the landing page
// based on the section type.
export default function LandingSectionsRenderer({ sections = [], quickViewModalData }) {
  const sectionComponents = useMemo(() => ({
    [SECTION_TYPES.CAROUSEL]: CarouselSection,
    [SECTION_TYPES.CONTENT_IMAGE]: ContentImageSection,
    [SECTION_TYPES.HERO]: HeroSection,
    [SECTION_TYPES.HERO_FOOTER]: HeroFooterSection,
    [SECTION_TYPES.LIST_IMAGE]: ListImageSection,
    [SECTION_TYPES.TITLE]: TitleSection,
  }), [])

  return (
    <div className="landingSections">
      {sections.map((section) => {
        const SectionComponent = sectionComponents[section.section_type]
        if (!SectionComponent) {
          console.warn(`Unknown section type: ${section.section_type}. Please check the section configuration.`)
          return null
        }

        if (section.section_type === SECTION_TYPES.CAROUSEL) {
          return <SectionComponent key={section.node_id} quickViewModalData={quickViewModalData} section={section} />
        }

        return <SectionComponent key={section.node_id} section={section} />
      })}
    </div>
  )
}

LandingSectionsRenderer.propTypes = {
  quickViewModalData: PropTypes.object,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      node_id: PropTypes.string.isRequired,
      section_type: PropTypes.oneOf(Object.values(SECTION_TYPES)).isRequired,
    }).isRequired
  ).isRequired,
}
